function Portfolio() {
  return (
    <div>
      <h1>Portfolio Page</h1>
      <p>This is the Portfolio Page.</p>
    </div>
  );
}

export default Portfolio;
