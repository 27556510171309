function NotFound() {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
    </div>
  );
}

export default NotFound;
